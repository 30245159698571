<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          dark
          class="toolbar-with-tabs"
          color="success"
      >
        <v-toolbar-title>{{ $localize('application_information') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="$emit('closePreviewDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar dense class="toolbar-with-tabs elevation-0" style="flex: none;">
        <v-tabs v-model="tab"
                color="success"
                style="position:sticky; top: 0; z-index: 1;"
                show-arrows
        >
          <v-tab>{{ $localize('questionnaire') }}</v-tab>
          <v-tab>{{ $localize('balance') }}</v-tab>
          <v-tab>{{$localize('PROFIT')}}</v-tab>
        </v-tabs>
      </v-toolbar>
      <v-card-text>
        <v-tabs-items v-model="tab" class="pa-0 ma-0 tab-table-2">
          <v-tab-item class="pa-8 pt-4" style="width: 50%" eager><Questionnaire v-if="request" :item="request"  class="elevation-14"/></v-tab-item>
          <v-tab-item class="pa-8 pt-4" eager><Balance v-if="balance" :balanceProp="balance" class="elevation-14"/></v-tab-item>
          <v-tab-item class="pa-8 pt-4" eager><Profit v-if="profit" :profitProp="profit" class="elevation-14"/></v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Questionnaire from "./requestDetailInformation/Questionnaire";
import Balance from "./requestDetailInformation/Balance";
import Profit from "./requestDetailInformation/Profit";

export default {
  components: {Questionnaire, Balance, Profit},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => {},
    },
    balance: {
      type: Object,
      default: () => {},
    },
    profit: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return{
      tab: undefined,
    }
  }
}
</script>