<template>
  <v-simple-table fixed-header
                  dense
                  mobile-breakpoint="0"
                  style="height: 100%;"
                  class="table-with-borders"

  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center">
          {{  $localize('indicator_name') }}
        </th>
        <template>
          <template v-for="(h, h_idx) in balance.headers">
            <th :key="'ha' + h_idx">{{ $dateFormat(h.date) }}</th>
          </template>
        </template>
      </tr>
      </thead>
      <tbody>
      <template v-for="(r, r_idx) in balance.rows">
        <tr :key="'r' + r_idx">
          <td :class="r.class" :colspan="r.fullRow ? balance.headers.length + 1 : ''">
            {{ r.title }}
          </td>
          <template v-if="r.type && (r.type ==='total' || r.type ==='groupTotal') ">
            <template v-for="(rh, rh_idx) in balance.headers">
              <td :key="'r' + r_idx + 'rh' + rh_idx" :class="r.class">
                {{ $moneyFormat(r.values[rh_idx].value) }}
              </td>
            </template>
          </template>
        </tr>
        <template>
          <template v-for="(ri, ri_index) in r.items">
            <tr :key="'r' + r_idx + 'ri' + ri_index">
              <td>{{ri.dictionary.title}}</td>
              <template v-for="(rh, rh_idx) in balance.headers">
                <td :key="'r' + r_idx + 'ri' + ri_index + 'rh' + rh_idx" :class="rh.class">
                  {{ $moneyFormat(balance.rows[r_idx].items[ri_index].values[rh_idx].value)}}
<!--                  <money v-model.lazy="balance.rows[r_idx].items[ri_index].values[rh_idx].value"-->
<!--                         :rules="[rules.required]"-->
<!--                         :precision="0"-->
<!--                         dense-->
<!--                  ></money>-->
                </td>
              </template>
            </tr>
          </template>
        </template>
      </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

export default {
  props: {
    balanceProp: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      balance: this.balanceProp,
    }
  },

  watch: {
    balanceProp: {
      deep: true,
      handler(n, o) {
        if(JSON.parse(JSON.stringify(n)) !== JSON.parse(JSON.stringify(o))) {
          this.balance = n
          this.$emit('balanceUpdating')
        }
      },
    },
  },
}
</script>
