<template>
  <div class="view-wrapper">
    <v-toolbar class="elevation-0">
      {{ $localize('my_requests') }}
      <v-spacer/>
      <v-btn color="success"
             small
             class="elevation-6"
             @click="newRequest"
      >
        {{$localize('create_request')}}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar v-if="emptyItems" no-gutters class="h-full elevation-0">
      <v-spacer/>{{$localize('there_is_nothing_here_yet')}}<v-spacer/>
    </v-toolbar>
    <v-simple-table v-else
                    fixed-header
                    mobile-breakpoint="0"
                    class="elevation-5"
                    dense
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center">{{ $localize('company_name') }}</th>
          <th class="text-center">{{ $localize('the_purpose_of_attracting') }}</th>
          <th class="text-center">{{ $localize('status') }}</th>
          <th class="text-center">{{ $localize('actions') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="( item, rowIdx) in items">
          <tr :key="rowIdx">
            <td class="text-start" style="width: 40%">
              <v-row class="align-center justify-start my-1 ml-md-16">
                <v-avatar
                    class="blue-grey lighten-4 ma-2"
                    dark
                    size="40"
                >
                  <v-icon>mdi-clipboard-text</v-icon>
                </v-avatar>
                <div class="text-truncate">
                  <div class="subtitle-1">{{item.company.title}}</div>
                  <div class="subtitle-2">{{ $dateTimeFormat(item.date)}}</div>
                </div>
              </v-row>
            </td>
            <td class="text-center">{{ $localize(item.attracting) }}</td>
            <td class="text-center">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs"
                          v-on="on"
                          class="ml-3 mr-3"
                  >
                    {{ item.status === 'CREATE' ? 'mdi-note-plus-outline' : item.status === 'DRAFT' ? 'mdi-pencil-box-multiple-outline' : 'mdi-timelapse'}}
                  </v-icon>
                </template>
                <span>{{ $localize(item.status) }}</span>
              </v-tooltip>
            </td>
            <td class="text-center">
              <v-menu
                  bottom
                  left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                      v-for="(menu, i) in menuItems(item)"
                      :key="i"
                      link
                      @click="menuClick(menu, item)"
                  >
                    <v-row class="align-center">
                      <v-icon class="mx-2" color="success" small>{{ menu.icon }}</v-icon>
                      <span class="mr-2">{{ $localize(menu.title) }}</span>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogConfirm" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5">{{ $localize('proof_of_payment') }}</v-card-title>
        <v-divider/>
        <v-card-text style="height: 100%" class="pt-4 pb-0 input-number-without-spin-button">
          <v-text-field v-model="confirmPaymentItem.transaction"
                        :label="$localize('enter_a_check_number')"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        clearable
                        dense
          ></v-text-field>
          <vuetify-money v-model="confirmPaymentItem.sum"
                         :rules="[rules.required]"
                         :label="$localize('enter_payment_amount')"
                         :options="{prefix: 'KZT', precision: 0}"
                         outlined
                         clearable
                         dense
          ></vuetify-money>
          <v-text-field
              v-model="confirmPaymentItem.date"
              :label="$localize('payment_date')"
              type="datetime-local"
              outlined
              clearable
              dense
          >
          </v-text-field>
          <!--          <v-datetime-picker v-model="confirmPaymentItem.date"-->
          <!--                             :label="$localize('payment_date')"-->
          <!--                             :text-field-props="textFieldProps"-->
          <!--                             :date-picker-props="datePickerProps"-->
          <!--                             :time-picker-props="timePickerProps"-->
          <!--          >-->
          <!--            <template slot="dateIcon">-->
          <!--              <v-icon>mdi-calendar</v-icon>-->
          <!--            </template>-->
          <!--            <template slot="timeIcon">-->
          <!--              <v-icon>mdi-clock-outline</v-icon>-->
          <!--            </template>-->
          <!--          </v-datetime-picker>-->
          <v-autocomplete v-model="confirmPaymentItem.bank"
                          :items="banks"
                          :label="$localize('choose_a_bank')"
                          :rules="[rules.required]"
                          outlined
                          clearable
                          dense
          >
          </v-autocomplete>
          <v-file-input v-model="file"
                        accept="image/png, image/jpeg, application/pdf"
                        :label="$localize('attach_a_receipt')"
                        :rules="[rules.required]"
                        :prepend-icon="null"
                        prepend-inner-icon="mdi-file"
                        outlined
                        clearable
                        dense
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeConfirmDialog" :disabled="loading">{{$localize('cancel')}}</v-btn>
          <v-btn color="success"
                 :disabled="!isPaymentFormFilled || loading"
                 @click="confirmPayment">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogHistory" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5">{{ $localize('confirmation_history') }}
          <v-spacer/>
          <v-btn icon
                 @click="closeHistoryDialog"><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <template v-if="paymentList.length > 0">
          <v-simple-table
              fixed-header
              mobile-breakpoint="0"
              class="h-full pb-4"
              dense
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center">{{ $localize('check_number') }}</th>
                <th class="text-center">{{ $localize('amount') }}</th>
                <th class="text-center">{{ $localize('status') }}</th>
                <th class="text-center">{{ $localize('view') }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="payment in paymentList">
                <tr :key="payment.uid">
                  <td class="text-center">{{payment.transaction}}</td>
                  <td class="text-center">{{$moneyFormat(payment.sum)}}</td>
                  <td class="text-center">{{status(payment.complete)}}</td>
                  <td class="text-center">
                    <v-btn icon
                           @click="downloadFile(payment.fileUid)">
                      <v-icon>mdi-file-eye-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <div v-else class="pa-4 title text-center">{{$localize('no_data')}}</div>
      </v-card>
    </v-dialog>
    <RequsetDetailDialog :dialog="dialogPreview"
                         :request="request"
                         :balance="balance"
                         :profit="profit"
                         @closePreviewDialog="closePreviewDialog"
    />
  </div>
</template>

<script>
import RequsetDetailDialog from "./components/RequsetDetailDialog";

export default {
  name: "Requests",
  components: {RequsetDetailDialog},
  data() {
    return {
      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
      },
      selectedItem: undefined,
      items: [],
      loading: false,

      //confirmPayment
      dialogConfirm: false,
      dialogHistory: false,
      confirmPaymentItem: {
        transaction: undefined,
        sum: undefined,
        bank: undefined,
        fileUid: undefined,
        date: undefined,
      },
      file: undefined,
      banks: [],
      paymentList: [],

      /*      //date-picker
            textFieldProps: {
              rules: [value => !!value || this.$localize('mandatory_field')],
              prependInnerIcon: 'mdi-calendar',
              outlined: true,
              dense: true
            },
            datePickerProps: {
              min: "2021-12-01",
              max: new Date().toISOString().slice(0, 10),
              noTitle: true,
              color: 'success',
            },
            timePickerProps: {
              color: 'success',
              format: '24hr'
            },*/

      //detailRequest
      dialogPreview: false,
      request: undefined,
      balance: {
        requestUid: undefined,
        headers: [],
        rows: []
      },
      balanceCopy: undefined,
      profit: {
        requestUid: undefined,
        headers: [],
        rows: []
      },
      profitCopy: undefined,
      dictItemsOrder: [
        'Выручка',
        'Себестоимость продаж',
        'Валовая прибыль (убыток)',
        'Административные расходы',
        'в т.ч. амортизация',
        'Расходы по реализации',
        'Прибыль (убыток) от продаж',
        'Проценты к получению',
        'Проценты к уплате',
        'Прочие доходы',
        'Прочие расходы',
        'Прибыль (убыток) до налогообложения',
        'Текущий налог на прибыль',
        'Прочее',
        'Чистая прибыль (убыток)'
      ],
    }
  },

  computed: {
    isPaymentFormFilled(){
      if(this.loading) return false
      for(let key in this.confirmPaymentItem){
        if(key !== 'fileUid' && !this.confirmPaymentItem[key]) return false
      }
      if(!this.file) return false
      return true
    },
    emptyItems(){
      if(!this.items || (this.items && !this.items.length)) return true
      return false
      // console.log(this.items)
      // if(this.items && this.items.length) return true
      // else return false
      // console.log('this.items && this.items.length>>')
      // console.log(this.items && this.items.length)
      // return this.items && this.items.length
    }
  },

  watch:{
    loading(value){
      if(value){
        this.$appUtils.setOverlay(true)
      }else{
        this.$appUtils.setOverlay(false)
      }
    },
  },

  async mounted(){
    await this.init()
  },

  methods: {
    async init(){
      this.loading = true
      const requestsRes = await this.$fetch.get('api/request/get-my')
      if(requestsRes) this.items = requestsRes
      const banksRes = await this.$fetch.get('api/payment/get-banks')
      if(banksRes) this.banks = banksRes
      this.loading = false
    },

    newRequest(){
      this.$router.push('/startup/request/new').catch(() => {})
    },

    menuClick(menu, item){
      switch (menu.title){
        case 'confirm_payment' : this.openConfirmDialog(item); break;
        case 'payment_history' : this.openHistoryDialog(item); break;
        case 'preview' : this.openPreviewDialog(item); break;
        case 'edit' : this.$router.push('/startup/request/' + item.uid); break;
        default : console.log("menu>>")
      }
    },

    openConfirmDialog(item){
      this.selectedItem = item
      this.dialogConfirm = true
    },

    closeConfirmDialog(){
      this.dialogConfirm = false
      this.selectedItem = undefined
      for (let key in this.confirmPaymentItem){
        this.confirmPaymentItem[key] = undefined
      }
      this.file = undefined
    },

    openHistoryDialog(item){
      this.selectedItem = item
      this.getPaymentList()
      this.dialogHistory = true
    },

    closeHistoryDialog(){
      this.dialogHistory = false
      this.selectedItem = undefined
      this.paymentList = []
    },

    async openPreviewDialog(item){
      this.loading = true
      this.selectedItem = item
      await this.fetchRequestDetail()
      this.dialogPreview = true
      this.loading = false
    },

    closePreviewDialog(){
      this.dialogPreview = false
      this.selectedItem = undefined
      this.request=  undefined
      this.balance. requestUid = undefined
      this.balance.headers = []
      this.balance.rows = []
      this.profit.requestUid = undefined
      this.profit.  headers = []
      this.profit. rows = []
    },

    async fetchRequestDetail(){
      const resQuestionnarie = await this.$fetch.get('api/request/get?uid=' + this.selectedItem.uid)
      if(resQuestionnarie) {
        this.request = resQuestionnarie
        this.request.attracting = this.$localize(this.request.attracting)
        if(this.request.company) this.request.company.type = this.$localize(this.request.company.type)
        this.request.guarantee = this.request.guarantee ? this.$localize('yes') : this.$localize('no')
      }
      const resBalance = await this.$fetch.get('api/request/balance/get?requestUid=' + this.selectedItem.uid)
      if(resBalance) {
        this.balanceCopy = JSON.parse(JSON.stringify(resBalance))
        if(this.balanceCopy) this.balance.headers = this.balanceCopy.headers
        await this.initBalanceByType('ASSETS', resBalance)
        await this.initBalanceByType('PASSIVE', resBalance)
        this.calculateSum()
      }
      const resProfit = await this.$fetch.get('api/request/profit/get?requestUid=' + this.selectedItem.uid)
      if(resProfit) {
        this.profitCopy = JSON.parse(JSON.stringify(resProfit))
        if(this.profitCopy) this.profit.headers = this.profitCopy.headers
        await this.initProfit()
      }
    },

    initBalanceByType(type, balance){
      if(balance && balance.headers && balance.rows && balance.headers.length >= 0 && balance.rows.length >= 0){
        let dict = []
        balance.rows.forEach(b => {
          let parent = b.parent
          parent.items = []
          if(b.items){
            b.items.forEach(bi => {
              parent.items.push(bi.dictionary)
            })
          }
          dict.push(parent)
        })
        if(dict && dict.length){
          let dictByType = dict.filter(d => {return d.type === type && !d.deleted})
          if(dictByType && dictByType.length){
            //dictName
            this.balance.rows.push({
              title: this.$localize(type),
              fullRow: true,
              class: 'dict-name'
            })
            //dict groups
            dictByType.forEach((d, idx) => {
              if(d && d.items && d.items.length){
                let rowItem = {
                  title: d.title,
                  parent: d,
                  fullRow: true,
                  class: 'group-name',
                  items: []
                }
                d.items.forEach(dChild => {
                  if(dChild.deleted) return
                  let childRowItem = { dictionary: dChild, values:[], fullRow: false, class: ''}
                  this.balance.headers.forEach(h => {
                    const oldValue = this.balanceCopy && this.balanceCopy.rows && this.balanceCopy.rows.length ? this.searchInBalanceCopy(dChild.uid, h.date) : null
                    let childColItem ={ date: h.date, headerUid: h.uid, value: 0}
                    childRowItem.values.push(oldValue ? oldValue : childColItem)
                  })
                  rowItem.items.push(childRowItem)
                })
                this.balance.rows.push(rowItem)
                let total = {
                  title: this.$localize('section_total'),
                  fullRow: false,
                  class: 'total-line',
                  type: 'total',
                  dictUid: d.uid,
                  dictType: type,
                  values: [],
                  idx: idx
                }
                this.balance.headers.forEach(h => {
                  total.values.push({date: h.date, headerUid: h.uid, value: 0})
                })
                this.balance.rows.push(total)
              }
            })
          }
          let groupTotal = {
            title: type === 'ASSETS' ? this.$localize('assets_total') : this.$localize('passive_total'),
            fullRow: false,
            class: 'group-total-line',
            type: 'groupTotal',
            dictType: type,
            values: []
          }
          this.balance.headers.forEach(h => {
            groupTotal.values.push({ date: h.date, headerUid: h.uid, value: 0})
          })
          this.balance.rows.push(groupTotal)
        }
      }
    },

    searchInBalanceCopy(dictUid, date){
      let value = null
      if(!this.balanceCopy || !this.balanceCopy.rows) return value
      for(const el of this.balanceCopy.rows){
        if(el.items && el.items.length) {
          for(const i of el.items){
            if (i.dictionary && i.dictionary.uid && i.dictionary.uid === dictUid) {
              if (i.values && i.values.length) {
                for(const v of i.values){
                  if (v.date === date){
                    value = v
                    break;
                  }
                }
              }
            }
            if(value) break;
          }
        }
        if(value) break;
      }
      return value
    },

    searchInProfitCopy(dictUid, date){
      let value = null
      if(!this.profitCopy || !this.profitCopy.rows) return value
      for(const el of this.profitCopy.rows){
        if (el.dictionary && el.dictionary.uid && el.dictionary.uid === dictUid) {
          if (el.values && el.values.length) {
            for(const v of el.values){
              if (v.date === date){
                value = v
                break;
              }
            }
            if(value) break;
          }
        }
        if(value) break;
      }
      return value
    },

    //profit
    initProfit(){
      if(this.profitCopy && this.profitCopy.rows && this.profitCopy.rows.length) {
        let dict = []
        this.profitCopy.rows.forEach(b => {dict.push(b.dictionary)})
        if(dict && dict.length) {
          //sort dict
          dict = dict.map(e => {
            e.sortBy = this.dictItemsOrder.indexOf(e.title) !== -1 ? this.dictItemsOrder.indexOf(e.title) : dict.length;
            return e;
          });
          dict.sort((a, b) => a.sortBy - b.sortBy);
          let last = "Итого";
          dict.sort(function (x, y) {
            return x.title == last ? 1 : y.title == last ? -1 : 0;
          });

          let dictByType = dict.filter(d => {
            return d.type === 'PROFIT' && !d.deleted
          })
          if (dictByType && dictByType.length) {
            //dictName
            this.profit.rows.push({
              title: this.$localize('PROFIT'),
              fullRow: true,
              class: 'dict-name'
            })
            //dict groups
            dictByType.forEach(d => {
              let childRowItem = {dictionary: d, values: [], fullRow: false, class: ''}
              this.profit.headers.forEach(h => {
                const oldValue = this.profitCopy && this.profitCopy.rows && this.profitCopy.rows.length ? this.searchInProfitCopy(d.uid, h.date) : null
                let childColItem = {date: h.date, headerUid: h.uid, value: 0}
                childRowItem.values.push(oldValue ? oldValue : childColItem)
              })
              this.profit.rows.push(childRowItem)
            })
          }
        }
      }
    },

    //sums
    calculateSum(){
      let dictTotalsSum = []
      if(this.balance && this.balance.rows && this.balance.rows.length) {
        this.balance.rows.forEach(r => {
          if(r.items && r.items.length){
            dictTotalsSum.push({dictUid: r.parent.uid, dictType: r.parent.type, totals: this.getSum(r)})
          }
        })
        if(dictTotalsSum && dictTotalsSum.length){
          dictTotalsSum.forEach(el => {
            let total = this.balance.rows.find(r => (r.type === 'total' && r.dictUid === el.dictUid))
            el.totals.forEach((e, idx) => {
              total.values[idx].value = e
            })
          })
          this.totalByDictGroup('ASSETS', dictTotalsSum)
          this.totalByDictGroup('PASSIVE', dictTotalsSum)
        }
      }
    },

    getSum(item){
      let totals = []
      this.balance.headers.forEach((headEl, innerIdx) => {
        let sum = 0;
        item.items.forEach(el => {
          el.values.forEach(value => {
            if(headEl.date === value.date){
              if(value.value) sum += Number(value.value)
            }
          })
        })
        totals[innerIdx] = sum
      })
      return totals
    },

    totalByDictGroup(type, dictTotalsSum){
      let groupTotal = []
      groupTotal = this.balance.rows.find(r => r.type === 'groupTotal' && r.dictType === type)
      let groupTotalSum = dictTotalsSum.filter(d => d.dictType === type)
      if(groupTotal && groupTotal.values){
        for (let i = 0; i < groupTotal.values.length; i++) {
          let sum = 0
          for (let j = 0; j < groupTotalSum.length; j++) {
            sum += groupTotalSum[j].totals[i]
          }
          groupTotal.values[i].value = sum
        }
      }
    },

    async confirmPayment(){
      if(this.isPaymentFormFilled){
        this.loading = true
        const fileUploadRes = await this.$fetch.postFile('api/file/upload', this.file)
        if(fileUploadRes && fileUploadRes.status === 'ACCESS') {
          this.confirmPaymentItem.fileUid = fileUploadRes.message
          await this.$fetch.post('api/payment/send-for-request-verification?entryUid=' + this.selectedItem.uid, this.confirmPaymentItem)
          this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
        }else {
          this.$appUtils.setOverlay(false)
          this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
        }
        this.loading = false
        this.closeConfirmDialog()
      }
    },

    async downloadFile(fileUid){
      const res = await this.$fetch.get('api/file/custom-download?uid=' + fileUid)
      if(res.resource){
        //custom
        const byteCharacters  = atob(res.resource)
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        //custom
        window.open(window.URL.createObjectURL(new Blob([byteArray], {type: res.mime})), '_blank');
      }
    },

    async getPaymentList(){
      if(this.selectedItem) this.paymentList = await this.$fetch.get('api/payment/get-by-entry?entryUid=' + this.selectedItem.uid)
    },

    status(item){
      if(item) return this.$localize('confirmed')
      else return this.$localize('no_confirmed')
    },

    menuItems(item) {
      switch (item.status) {
        case 'DRAFT' :
          return [
            {title: "preview", icon: 'mdi-eye-outline'},
            {title: "edit", icon: 'mdi-pencil'},
          ]
        case 'CREATE' : {
          return [
            {title: "confirm_payment", icon: 'mdi-check'},
            {title: "payment_history", icon: 'mdi-cash-multiple'},
            {title: "preview", icon: 'mdi-eye-outline'},
            {title: "edit", icon: 'mdi-pencil'},
          ]
        }
        case 'PROCESS' : {
          return [
            {title: "payment_history", icon: 'mdi-cash-multiple'},
            {title: "preview", icon: 'mdi-eye-outline'},
          ]
        }
        default : return []
      }
    }
  }
}
</script>