<template>
  <v-card>
    <v-card-text style="height: 100% !important;">
      <v-text-field v-model="item.company.title"
                    :label= "$localize('full_company_name')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.bin"
                    :label= "$localize('bin_of_the_company')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.type"
                    :label= "$localize('company_type')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <!--        //учредитель-->
      <v-text-field v-model="item.company.fullNameDirector"
                    :label= "$localize('full_name_of_the_founder_s')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.iinDirector"
                    :label= "$localize('iin_of_the_founder')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.experience"
                    :label= "$localize('work_experience_of_the_founder_s__max_months')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.phone"
                    :label= "$localize('founder_s_phone')"
                    prepend-inner-icon="mdi-phone"
                    return-masked-value
                    v-mask="'+7(###)-###-##-##'"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.email"
                    :label= "$localize('founder_email')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <!--      первый руководитель-->
      <v-text-field v-model="item.company.fullNameMainDirector"
                    :label= "$localize('full_name_of_the_first_head')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.iinMainDirector"
                    :label= "$localize('iin_of_the_first_head')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.experienceMainDirector"
                    :label= "$localize('work_experience_of_the_first_head_months')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.phoneMainDirector"
                    :label= "$localize('cell_phone_of_the_first_head')"
                    prepend-inner-icon="mdi-phone"
                    return-masked-value
                    v-mask="'+7(###)-###-##-##'"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.company.emailMainDirector"
                    :label= "$localize('email_of_the_first_manager')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-if="item.company.activity"
                    v-model="item.company.activity.title"
                    :label= "$localize('field_of_activity')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <vuetify-money v-model="item.company.cashlessTurnover"
                     :label= "$localize('cashless_turnover_in_the_company_tenge')"
                     :options="{prefix: 'KZT', precision: 0}"
                     readonly
                     outlined
                     dense
      ></vuetify-money>
      <v-text-field v-model="item.company.accounting"
                    :label= "$localize('what_is_the_accounting')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <v-text-field v-model="item.attracting"
                    :label= "$localize('the_purpose_of_attracting')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
      <vuetify-money v-model="item.amount"
                     :label= "$localize('requested_amount_tenge')"
                     :options="{prefix: 'KZT', precision: 0}"
                     readonly
                     outlined
                     dense
      ></vuetify-money>
      <v-text-field v-model="item.guarantee"
                    :label= "$localize('providing_a_personal_guarantee_to_the_founder_s')"
                    outlined
                    dense
                    readonly
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Questionnaire",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  }
}
</script>
